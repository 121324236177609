/* Dot opacity loader */

.dot-opacity-loader {
    width: 50px;
    height: 10px;
    border-radius: 100%;
    position: relative;
    top: -5px;
    margin: 10px auto;
    justify-content: center;
}


.dot-opacity-loader span{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $primary;
    margin: 0px 3px;
    opacity: 0;
}
  
.dot-opacity-loader span:nth-child(1){
    animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2){
    animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3){
    animation: opacitychange 1s ease-in-out 0.66s infinite;
}
  
@keyframes opacitychange{
    0%, 100%{
        opacity: 0;
    }

    60%{
        opacity: 1;
    }
}