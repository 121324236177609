/* Modals */

.modal-dialog {
  margin-top: 50px;
  margin-bottom: 50px;

  .modal-content {
    border: 1px solid $modal-border-color;

    .modal-header {
      padding: $modal-header-padding-y $modal-header-padding-x;
      border-bottom: 1px solid $modal-border-color;

      .close {
        border: none;
        background: transparent;

        span {
          font-size: 32px;
          font-weight: 400;
          color: #6a6a6a;
        }
      }
    }

    .modal-body {
      padding: $modal-body-padding-y $modal-body-padding-x;
      border-bottom: 1px solid $modal-border-color;
    }

    .modal-footer {
      padding: $modal-footer-padding-y $modal-footer-padding-x;

      @media(max-width: 400px) {
        padding-left: .25rem;
        padding-right: .25rem;
        flex-direction: column;

        >:not(:last-child) {
          margin-right: 0;
          margin-bottom: .7rem;
        }

        >:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}



.center-modal {
  &.modal {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .center-modal {
    &.modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
  }
  
}

.center-modal {
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
} 
