/* Dashboard */


#performance-line-legend {
  .chartjs-legend {
      margin-right: 20px;

      ul {
          li {
              color: $text-muted;
              font-size: 12px;

              span {
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
              }
          }
      }
  }
}
.home-tab {
  .btn {
    padding: 8px 15px;
    border: 1px solid $btn-outline-border;
    box-sizing: border-box;
    border-radius: 6px; 
    color: $black;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
    align-items: center;
    @media (max-width:991px) {
      padding: 8px 12px;
    }
    i {
      font-size: 1rem;
      margin-right: .25rem;
      line-height: 1;
      vertical-align: bottom;
      .rtl & {
        margin-right: 0;
        margin-left: .25rem;
      }
    }
    &.btn-lg {
        font-size: 14px;
        line-height: 18px;
        padding: 10px;
    }
    &.upgrade-btn {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 23px;
      padding: 15px 24px;
      color: $white;
      border: none;
      margin-top: 21px;
    }
    &.btn-icons {
      width: 24px;
      height: 24px;
      padding: 0;
      padding-top: 1px;
      text-align: center;
      i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }
  }
  .btn-wrapper {
    .btn {
      i {
        font-size: .75rem;
      }
    }
  }
  .dropdown {
    .btn {
      border: none;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .statistics-details {
    margin-bottom: 20px;
    .statistics-title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: $text-light-grey;
      margin-bottom:4px;
      @media (max-width:991px){
        font-size: 12px;
        }
    }
    .rate-percentage {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      color: $black;
      margin-bottom: 0;
      @media (max-width:991px){
      font-size: 18px;
      }
    }
    .status {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      i {
        font-size: 12px;
      }
    }
    p {
      font-weight: bold;
      font-size: 12px; 
      i {
        font-size: 20px;
      }
    }
  }
  .chartjs-wrapper {
      height: 150px !important;
  }
  .status-summary-ight-white {
      color: $white-6;
  }
  .status-summary-chart-wrapper {
      height: 90px;
  }
  .circle-progress-width {
      width: 45px;
      @media (max-width:991px){
        width: 50%;
        margin-bottom: 20px;
      }
  }
  
  h3 {
      &.upgrade-info {
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 40px;
          text-align: left;
      }
  }
  .chartjs-legend {
    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      align-items: center;
      margin-bottom: 0;
  
      li {
        margin-right: 8%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: $text-muted;
        font-size: 10px;
  
        span {
          &.amount-type-box {
          height: 10px;
          width: 10px;
          border-radius: 100%;
          margin-right: 10px;
          display: inline-block;
          }
          &.amount-type-text {
            font-size: $default-font-size;
          }
        }
      }
    }
  
    .rtl & {
      ul {
        padding-right: 0;
  
        li {
          margin-right: 0;
          margin-left: 8%;
  
          span {
            margin-right: 0;
            margin-left: 1rem;
          }
        }
      }
    }
  }
  .text-light-green {
    color: $link-green;
  }
  .nav-tabs {
    .nav-link {
      background: $nav-tabs-link-bg;
      color: $nav-tabs-link-color;
      border-radius: 0;
      border: 1px solid $border-color;
      border-top: none;
      padding: 12px 14px;
      font-size: $default-font-size;
      @media (max-width: 767px) {
        padding: .75rem 1rem;
      }
      &:last-child {
        border-right: none;
      }
      &:first-child {
        border-left: none;
      }
      &.active {
        color: $primary;
      }
    }
    .nav-item {
      border-right: 1px solid $border-color;
      &:first-child {
        .nav-link {
          border-radius: 4px 0 0 0;
          padding-left: 0;
        }	
      }
      &:last-child {
      border-right: none;
        .nav-link {
          border-radius: 0 4px 0 0;
        }
      }
    }
  }
  .tab-content {
    border: none;
    padding: 24px 0;
  }
  
}


///// to be delete

.performance-overview-carousel {

  .carousel-item {
    .item {
      &::after {
        content: '';
        width: 1px;
        height: 60px;
        background: $info;
        position: absolute;
        right: 0;
        top: 25%;
        @media(max-width: 767px) {
          display: none;
        }
      }
    }  
  }
  .slick-slider {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: -30px;
        width: 1.875rem;
        height: 1.875rem;
        background: #3698fc;
        border-radius: 0.375rem;
      }
      &.slick-prev {
        left: auto;
        right: 2.2rem;
        &:before {
          font-family: "themify";
          content: '\e64a';
          font-size: .875rem;
          color: $white;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
      &.slick-next {
        left: auto;
        right: 0rem;
        &:before {
          font-family: "themify";
          content: '\e649';
          font-size: .875rem;
          color: $white;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
    }
  }
}

.detailed-reports-carousel {

  .slick-slider {
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        top: -30px;
        width: 1.875rem;
        height: 1.875rem;
        background: #ecedf2;
        border-radius: 0.375rem;
      }
      &.slick-prev {
        left: auto;
        right: 2.2rem;
        &:before {
          font-family: "themify";
          content: '\e64a';
          font-size: .875rem;
          color: #6c7383;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
      &.slick-next {
        left: auto;
        right: 0rem;
        &:before {
          font-family: "themify";
          content: '\e649';
          font-size: .875rem;
          color: #6c7383;
          line-height: 1.875rem;
          margin-right: 0.48rem;
        }
      }
    }
  }

  .report-table {
    &::after {
      content: '';
      width: 1px;
      height: 174px;
      background: #b1b1b5;
      position: absolute;
      right: -1rem;
      top: 15%;
      @media(max-width: 767px) {
        display: none;
      }
    }
  }
}

.sales-report-legend {
  ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    @include display-flex;
    @include align-items(flex-end);
    @include flex-direction(column);
    @include flex-wrap(wrap);
    li {
      @include display-flex;
      @include align-items(center);
      span {
        width: 2.25rem;
        height: 1rem;
        margin-right: .4rem;
        display: inline-block;
        font-size: $default-font-size;
      }
      &:nth-child(1) {
        span {
          background: #ffc100;
        }
      }
      &:nth-child(2) {
        span {
          background: #f5a623;
        }
      }
    }
  }
}

.detailed-reports-chart-legend {
  .item {
    .bullet {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
  &.north-america-legend {
    .item {
      &:nth-child(1) {
        .bullet {
          background: $success;          
        }
      }
      &:nth-child(2) {
        .bullet {
          background: $warning;          
        }
      }
      &:nth-child(3) {
        .bullet {
          background: $primary;          
        }
      }
    }
  }
  &.south-america-legend {
    .item {
      &:nth-child(1) {
        .bullet {
          background: $warning;          
        }
      }
      &:nth-child(2) {
        .bullet {
          background: $primary;          
        }
      }
      &:nth-child(3) {
        .bullet {
          background: $success;          
        }
      }
    }
  }
}

.charts-data {
  .progress {
    background: transparent;
    .progress-bar {
      border-radius: 11px;
    }
  }
}